import React from 'react';
import {PlanSearchResult} from '../../api';
import DownloadStatusIndicator from '../general/DownloadStatus';
import Icon from 'react-native-vector-icons/FontAwesome';
import moment from 'moment';
import {Card, Title} from '../../styled-components/Card';
import {Row} from '../../styled-components/Row';
import {Field} from '../../styled-components/Field';
import {Light} from '../../styled-components/Text';
import {DownloadStatus} from '../../hooks/usePlans';
import styled from 'styled-components/native';

const PlanInfo = styled(Field)`
  max-width: 90%;
`;

interface PlanResultProps {
  plan: PlanSearchResult;
  status: DownloadStatus;
}

const PlanResult = ({plan, status}: PlanResultProps) => {
  return (
    <Card>
      <Row>
        <PlanInfo>
          <Title>{plan.Name}</Title>
          <Light>
            {(plan.PreviewSearchTermPasswordRequired ||
              plan.SearchTermPasswordRequired) && (
              <>
                <Icon name="lock" size={16} color="#666666" />
                &nbsp;&nbsp;•&nbsp;&nbsp;
              </>
            )}
            {moment(plan.PublishedDate).format('MMM D, Y')}
            &nbsp;&nbsp;•&nbsp;&nbsp;V.
            {plan.Version}
          </Light>
        </PlanInfo>
        <DownloadStatusIndicator status={status} />
      </Row>
    </Card>
  );
};

export default PlanResult;
