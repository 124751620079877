import React, { useState } from 'react';
import Pdf from 'react-native-pdf';
import { Document as PdfDocument, Page as PdfPage, pdfjs } from 'react-pdf';
import styled from 'styled-components';
import { contentUrl, isWeb } from '../../../api';
import { ScrollView } from '../../general/ScrollView';
import {windowIsWeb, windowIsWebDesktop} from '../../../styled-components/windowIsWeb';

// TODO: move this to our own CDN but this should be safe for now
pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.5.207/pdf.worker.min.js';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  ${windowIsWeb && !windowIsWebDesktop &&
  `
    padding-bottom: 142px;
  `};
  ${windowIsWeb && windowIsWebDesktop &&
  `
    padding-bottom: 54px;
  `};
`;

const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const ButtonWrapper = styled.button`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;

const WebPdfWrapper = styled.div`
  max-width: 80vw;
  overflow: scroll;
  margin: 0 auto;
`;

const MobilePdfWrapper = styled(Pdf)`
  flex-grow: 1;
  width: 100%;
`;

interface PDFContentProps {
  content: any;
  planId: string;
  contentSignature: string;
};

const SCALE_FACTOR = 0.25;

const PDFContent = ({
  content,
  planId,
  contentSignature,
}: PDFContentProps) => {
  const pdfUrl = `${contentUrl}/${planId}/${content.contentID}/${content.file!}${contentSignature}`;
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoomScale, setZoomScale] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const previousPage = () => setPageNumber(pageNumber - 1);
  const nextPage = () => setPageNumber(pageNumber + 1);

  const zoomIn = () => setZoomScale(zoomScale + SCALE_FACTOR);
  const zoomReset = () => setZoomScale(1);
  const zoomOut = () => setZoomScale(zoomScale - SCALE_FACTOR);

  const pagination = () => {
    if (numPages <= 1) {
      return (
        <PaginationWrapper>
          <ButtonWrapper type="button" onClick={zoomOut} disabled={zoomScale - SCALE_FACTOR <= 0}>
            Zoom Out
          </ButtonWrapper>
          <ButtonWrapper type="button" onClick={zoomReset}>
            Reset
          </ButtonWrapper>
          <ButtonWrapper type="button" onClick={zoomIn} disabled={zoomScale - SCALE_FACTOR >= 4}>
            Zoom In
          </ButtonWrapper>
        </PaginationWrapper>
      )
    };

    return (
      <PaginationWrapper>
        <ButtonWrapper
          type="button"
          disabled={pageNumber <= 1}
          onClick={previousPage}
        >
          Previous
        </ButtonWrapper>
        <span>
          Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
        </span>
        <ButtonWrapper
          type="button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
          Next
        </ButtonWrapper>
        <ButtonWrapper type="button" onClick={zoomOut} disabled={zoomScale - SCALE_FACTOR <= 0}>
          Zoom Out
        </ButtonWrapper>
        <ButtonWrapper type="button" onClick={zoomReset}>
          Reset
        </ButtonWrapper>
        <ButtonWrapper type="button" onClick={zoomIn} disabled={zoomScale - SCALE_FACTOR >= 4}>
          Zoom In
        </ButtonWrapper>
      </PaginationWrapper>
    );
  };

  if (isWeb) {
    return (
      <ScrollView>
        <ContentWrapper>
          {pagination()}
          <WebPdfWrapper>
            <PdfDocument file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
              <PdfPage pageNumber={pageNumber} scale={zoomScale} />
            </PdfDocument>
          </WebPdfWrapper>
          {pagination()}
        </ContentWrapper>
      </ScrollView>
    );
  }

  return (
    <MobilePdfWrapper
      source={{
        uri: pdfUrl,
      }}
      fitPolicy={0}
      style={{
        backgroundColor: 'rgb(241, 243, 254)',
        paddingBottom: 100,
      }}
    />
  );
};

export default PDFContent;
