import React, {useContext} from 'react';
import {ScrollView} from '../general/ScrollView';
import Screen from '../../styled-components/Screen';
import PlansContext from '../../contexts/PlansContext';
import {Plan} from '../../database/schemas/plan';
import {useNavigation} from '@react-navigation/core';
import {NativeStackNavigationProp} from 'react-native-screens/lib/typescript/types';
import {ContactsStackParamList} from './ContactRoutes';
import PlanListItem from './PlanListItem';
import {Description, Header, Title} from '../../styled-components/Header';
import {ListItem} from '../../styled-components/List';
import {Download} from '../../hooks/usePlans';

const PlanList = () => {
  const navigation =
    useNavigation<NativeStackNavigationProp<ContactsStackParamList>>();
  const context = useContext(PlansContext);
  const downloaded = context!.plans.downloads
    .map((d: Download) => `planID != "${d.planId}"`)
    .join(' AND ');
  const plans = context!.plans.downloads.length
    ? context!.doa.getCollection<Plan>('Plan', downloaded)
    : context!.doa.getCollection<Plan>('Plan');

  return (
    <>
      <Header>
        <Title>Plan contacts</Title>
        <Description>Select plan to see its contacts</Description>
      </Header>
      <ScrollView>
        <Screen>
          {plans.map((plan: Plan) => (
            <ListItem
              key={plan.planID}
              onPress={() =>
                plan.contacts.length &&
                navigation.push('ContactList', {
                  planId: plan.planID,
                })
              }>
              <PlanListItem plan={plan} />
            </ListItem>
          ))}
        </Screen>
      </ScrollView>
    </>
  );
};

export default PlanList;
