import React, {useContext, useState} from 'react';
import {
  NativeSyntheticEvent,
  TextInputChangeEventData,
  Text,
} from 'react-native';
import {NativeStackNavigationProp} from 'react-native-screens/lib/typescript/types';
import {RouteProp, useRoute, useNavigation} from '@react-navigation/core';
import debounce from 'lodash/debounce';
import InputBox, {TextInput} from '../general/InputBox';
import NotFound from '../general/NotFound';
import ScrollView from '../general/ScrollView';
import PlansContext from '../../contexts/PlansContext';
import {Contact} from '../../database/schemas/contact';
import {ContactsStackParamList} from './ContactRoutes';
import ContactListItem from './ContactListItem';
import {Header, Title} from '../../styled-components/Header';
import Screen from '../../styled-components/Screen';
import {ListItem} from '../../styled-components/List';

type ContactListRouteProp = RouteProp<ContactsStackParamList, 'ContactList'>;

const ContactList = () => {
  const {
    params: {planId},
  } = useRoute<ContactListRouteProp>();
  const navigation = useNavigation<
    NativeStackNavigationProp<ContactsStackParamList>
  >();
  const [searchTerm, setSearchTerm] = useState('');
  const context = useContext(PlansContext);
  const contacts = context!.doa.searchContacts(planId, searchTerm);

  const handleChange = debounce((value: string) => {
    if (value) {
      setSearchTerm(value);
    } else {
      setSearchTerm('');
    }
  }, 300);

  return (
    <>
      <Header>
        <Title>Contacts</Title>
        <InputBox background="rgba(0, 0, 0, 0.2)" color="white" icon="search">
          <TextInput
            autoCapitalize="none"
            color="white"
            placeholder="Search"
            placeholderTextColor="white"
            autoCompleteType="off"
            onChange={(e: NativeSyntheticEvent<TextInputChangeEventData>) =>
              handleChange(e.nativeEvent.text)
            }
          />
        </InputBox>
      </Header>
      <ScrollView>
        <Screen>
          {!!contacts.length ? (
            contacts.map((contact: Contact) => (
              <ListItem
                key={contact.id}
                onPress={() =>
                  navigation.push('Contact', {planId, contactId: contact.id})
                }>
                <ContactListItem key={contact.id} contact={contact} />
              </ListItem>
            ))
          ) : (
            <NotFound>
              <Text>No results from "{searchTerm}"</Text>
            </NotFound>
          )}
        </Screen>
      </ScrollView>
    </>
  );
};

export default ContactList;
