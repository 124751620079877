import React, {useContext} from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import Header from '../general/Header';
import PlanList from './PlanList';
import Content from './content/Content';
import DeletePlanButton from './DeletePlanButton';
import PlansContext from '../../contexts/PlansContext';
import {Plan} from '../../database/schemas/plan';
import {Contents} from '../../database/schemas/content';
import {Alert} from 'react-native';

export type PlanAction = {
  type: 'check for updates' | 'delete plan';
  planIds?: string[];
};

type ContentParams = {
  planId: string;
  contentId?: string;
};

export type PlanStackParamList = {
  PlansList: {action: PlanAction} | undefined;
  Content: ContentParams;
};

const Stack = createStackNavigator<PlanStackParamList>();

const PlanRoutes = () => {
  const context = useContext(PlansContext);

  const checkForUpdates = () => {
    context!.plans
      .checkForUpdates()
      .then(({updated, removed}) => {
        if (updated.length) {
          context!.sendNotification(
                      'info',
                      `Updating ${updated.length} plan${
                        updated.length > 1 ? 's' : ''
                      }`,
                    )
        Promise.all(updated.map(context!.plans.updatePlan)).then(() => 
                context!.sendNotification('info',
                `Finished updating ${updated.length} plan${
                    updated.length > 1 ? 's' : ''
                  }`,
                )
        );
        }

        if (removed.length) {
          removed.forEach((plan: Plan) => {
            const name = plan.name;
            context!.plans
              .deletePlan(plan.planID)
              .then(() =>
                context!.sendNotification('info', `${name} has been removed`),
              )
              .catch((error) => console.log('Error deleting plan', error));
          });
        }
      })
      .catch((error) => console.log('Error checking for updates', error));
  };

  // Execute pending actions when arriving on the PlanList route
  // - Delete plan
  const afterRouteToPlanList = ({action}: {action: PlanAction}) => {
    const {type, planIds} = action;
    switch (type) {
      case 'delete plan':
        const name = context!.doa.getDocument<Plan>('Plan', planIds![0])!.name;

        return context!.plans
          .deletePlan(planIds![0])
          .then(() =>
            context!.sendNotification('info', `${name} has been deleted`),
          )
          .catch((error) => console.log('Error deleting plan', error));
    }
  };

  return (
    <Stack.Navigator
      initialRouteName="PlansList"
      headerMode="screen"
      screenOptions={{header: (props) => <Header {...props} />}}>
      <Stack.Screen
        name="PlansList"
        component={PlanList}
        listeners={({route}) => ({
          focus: () => checkForUpdates(),
          transitionEnd: () =>
            route.params && afterRouteToPlanList(route.params),
        })}
      />
      <Stack.Screen
        name="Content"
        component={Content}
        options={({route}) => {
          const colors = Contents.headerColors(
            context!.doa,
            route.params?.contentId,
          );
          return {
            headerBackTitle: '',
            headerTintColor: colors.tint,
            headerStyle: {
              backgroundColor: colors.background,
            },
            headerRight: route.params.contentId
              ? undefined
              : () => (
                  <DeletePlanButton
                    planId={route.params.planId}
                    color={colors.tint}
                  />
                ),
          };
        }}
      />
    </Stack.Navigator>
  );
};

export default PlanRoutes;
