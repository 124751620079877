/// <reference lib="dom" />
import {PlanInfo} from '../api';
import {DOA as IDOA} from './doa';
import {Contents, ExpandableContent, ListContent} from './schemas/content';
import {Contact} from './schemas/contact';
import {Plan} from './schemas/plan';

export const appName = process.env.EXPO_APP_NAME || 'my-eop';

// class Collection<T> extends Array<T> implements ICollection<T> {
//   _collection: T[];
//
//   constructor(collection: T[]) {
//     debugger;
//     super(...collection);
//     this._collection = collection;
//   }
//
//   filtered(query: string) {
//     console.log(query);
//     return new Collection(this._collection);
//   }
//
//   sorted(query: string) {
//     console.log(query);
//     return new Collection(this._collection);
//   }
// }

class DOA implements IDOA {
  db: {
    plan: Plan[];
  };

  constructor() {
    const plan = JSON.parse(window.localStorage.getItem('doa-plan') || '[]');
    this.db = {
      plan,
    };
  }

  savePlan(plan: PlanInfo, password: string): void {
    console.log('Saving plan', plan.PlanID);
    this.db.plan.push(Plan.apiToModel(plan, password));
    window.localStorage.setItem('doa-plan', JSON.stringify(this.db.plan));
  }

  updatePlan(plan: PlanInfo, password: string) {
    const updatedPlan = Plan.apiToModel(plan, password);
    console.info('Updating plan ', plan.PlanID);
    this.db.plan = this.db.plan.map((savedPlan: Plan) =>
      savedPlan.planID === updatedPlan.planID ? updatedPlan : savedPlan,
    );
    window.localStorage.setItem('doa-plan', JSON.stringify(this.db.plan));
    return Promise.resolve();
  }

  deletePlan(planId: string) {
    console.log('Deleting plan', planId);
    this.db.plan = this.db.plan.filter((p: Plan) => p.planID !== planId);
    window.localStorage.setItem('doa-plan', JSON.stringify(this.db.plan));
    return Promise.resolve();
  }

  getContacts(): Contact[] {
    return this.db.plan.flatMap((p: Plan) => p.contacts);
  }

  getCollection<T>(
    name: string,
    query?: (p: Plan) => T[],
    sort?: (p1: T, p2: T) => T[],
  ): T[] {
    const collection =
      name === 'Plan'
        ? this.db.plan
        : name === 'Contact'
        ? this.getContacts()
        : [];

    const filtered =
      query && typeof query === 'function'
        ? collection.filter(query)
        : collection;
    return sort ? filtered.sort(sort) : filtered;
  }

  getDocument<T>(collection: string, id: string): T | undefined {
    console.log('Getting document', collection, id);
    switch (collection) {
      case 'Plan':
        return this.db.plan.find((p: Plan) => p.planID === id) as unknown as T;
      case 'Contact':
        return this.getContacts().find(
          (c: Contact) => c.id === id,
        ) as unknown as T;
      case 'Contents':
        return this.getAllContent().find(
          (c: Contents) => c.contentID === id,
        ) as unknown as T;
      case 'ListContent':
        return this.getListContent().find(
          (c: ListContent) => c.nextPlanContentID === id,
        ) as unknown as T;
      case 'ExpandableContent':
        return this.getExpandableContent().find(
          (c: ExpandableContent) => c.expandableContentID === id,
        ) as unknown as T;
      default:
        return undefined;
    }

    // return documents.filtered(query).length
    //   ? ((documents.filtered(query)[0] as unknown) as T)
    //   : undefined;
  }

  getExpandableContent(): ExpandableContent[] {
    return this.getAllContent().flatMap((c: Contents) => c.expandableContents);
  }

  getListContent(): ListContent[] {
    return this.getAllContent().flatMap((c: Contents) => c.listContents);
  }

  getAllContent(): Contents[] {
    return this.db.plan.flatMap((p: Plan) => p.contents);
  }

  getContent(planId: string, contentId?: string): Contents | undefined {
    const plan = this.db.plan.find((p: Plan) => p.planID === planId);

    if (!plan) {
      return undefined;
    }

    return contentId
      ? plan!.contents.find((c: Contents) => c.contentID === contentId)
      : plan!.contents.find((c: Contents) => c.isRootContent);
  }

  searchContacts(planId: string, searchTerm: string): Contact[] {
    const plan = this.db.plan.find((p: Plan) => p.planID === planId);
    const contacts = searchTerm
      ? plan!.contacts.filter(
          (c: Contact) =>
            c.name?.includes(searchTerm) || c.email?.includes(searchTerm),
        )
      : plan!.contacts.filter((c: Contact) => !!c);

    return contacts.sort((a: Contact, b: Contact) => {
      return a?.name?.localeCompare(b?.name ?? '') ?? 0;
    })
  }
}

export default DOA;
