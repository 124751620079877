import React, {useContext} from 'react';
import {View, TouchableOpacity, Linking} from 'react-native';
import {RouteProp, useRoute} from '@react-navigation/core';
import ScrollView from '../general/ScrollView';
import PlansContext from '../../contexts/PlansContext';
import {ContactsStackParamList} from './ContactRoutes';
import Screen from '../../styled-components/Screen';
import {Card} from '../../styled-components/Card';
import {Row} from '../../styled-components/Row';
import {Field, Label, Value} from '../../styled-components/Field';
import IconLink from '../general/IconLink';
import openMap from 'react-native-open-maps';
import {Description, Header, Title} from '../../styled-components/Header';
import {Contact as ContactSchema} from '../../database/schemas/contact';

type ContactRouteProp = RouteProp<ContactsStackParamList, 'Contact'>;

const Contact = () => {
  const {
    params: {contactId},
  } = useRoute<ContactRouteProp>();
  const context = useContext(PlansContext);
  const contact = context!.doa.getDocument<ContactSchema>(
    'Contact',
    contactId,
  )!;

  const fields: Element[] = [];

  if (contact.company) {
    fields.push(
      <Field>
        <Label>Company</Label>
        <Value>{contact.company}</Value>
      </Field>,
    );
  }
  if (contact.phoneNumbers.length) {
    fields.push(
      ...contact.phoneNumbers.map(({label, digits}) => (
        <>
          <Field>
            <TouchableOpacity onPress={() => Linking.openURL(`tel:${digits}`)}>
              {label && <Label>{label}</Label>}
              <Value>{digits}</Value>
            </TouchableOpacity>
          </Field>
          <IconLink link={`tel:${digits}`} icon="phone" />
        </>
      )),
    );
  }
  if (contact.email) {
    fields.push(
      <>
        <Field>
          <TouchableOpacity
            onPress={() => Linking.openURL(`mailto:${contact.email}`)}>
            <Label>Email</Label>
            <Value>{contact.email}</Value>
          </TouchableOpacity>
        </Field>
        <IconLink link={`mailto:${contact.email}`} icon="email" />
      </>,
    );
  }
  if (contact.address1 || contact.city || contact.state || contact.postalCode) {
    const mapLink = () =>
      openMap({
        query: `${contact.address1} ${contact.address2} ${contact.city} ${contact.state} ${contact.postalCode}`,
      });
    fields.push(
      <>
        <Field>
          <TouchableOpacity onPress={mapLink}>
            <Label>Address</Label>
            <View>
              {contact.address1 && <Value>{contact.address1}</Value>}
              {contact.address2 && <Value>{contact.address2}</Value>}
              {(contact.city || contact.state || contact.postalCode) && (
                <Value>
                  {contact.city} {contact.state} {contact.postalCode}
                </Value>
              )}
            </View>
          </TouchableOpacity>
        </Field>
        <IconLink link={mapLink} icon="map" />
      </>,
    );
  }

  return (
    <>
      <Header>
        <Title>
          {contact.name
            ? contact.name
            : contact.title
            ? contact.title
            : 'Contact'}
        </Title>
        {!!contact.name && !!contact.title && (
          <Description>{contact.title}</Description>
        )}
      </Header>
      <ScrollView>
        <Screen>
          <Card>
            {fields.map((field, i) => (
              <Row key={i} border={i !== fields.length - 1}>
                {field}
              </Row>
            ))}
          </Card>
        </Screen>
      </ScrollView>
    </>
  );
};

export default Contact;
