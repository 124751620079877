import {useState} from 'react';
import api, {MediaInfo, PlanInfo, PlanSearchResult} from '../api';
import {Plan} from '../database/schemas/plan';
import RNFetchBlob from 'rn-fetch-blob';
import {DOA} from '../database/doa';
import {Platform} from 'react-native';

export const plansDir = `${RNFetchBlob.fs.dirs.DocumentDir}/plans`;

export type DownloadStatus = 'ready' | 'pending' | 'downloaded' | 'error';

export type Download = {
  planId: string;
  status: DownloadStatus;
};

export type UpdatesResponse = {
  updated: Plan[];
  removed: Plan[];
};

export interface PlanManager {
  download: (id: string, password?: string) => Promise<null>;
  downloads: Download[];
  downloadStatus: (id: string) => DownloadStatus;
  deletePlan: (id: string) => Promise<void>;
  deleting: boolean;
  checkForUpdates: () => Promise<UpdatesResponse>;
  updatePlan: (plan: Plan) => Promise<void>;
}

const usePlans = (doa: DOA): PlanManager => {
  const [downloads, setDownloads] = useState<Download[]>([]);
  const [deleting, setDeleting] = useState<boolean>(false);

  const deletePlan = (planId: string) => {
    setDeleting(true);
    return doa
      .deletePlan(planId)
      .then(() => setDeleting(false))
      .catch(error => {
        console.error(error);
        setDeleting(false);
      });
  };

  const download = (plan: PlanInfo, password: string = '') => {
    // Download plan content
    if (Platform.OS === 'web') {
      // TODO: Get signed urls for plan content
      setTimeout(() => {
        doa.savePlan(plan, password);
        setDownloads(downloads.filter(d => d.planId === plan.PlanID));
      });
    } else {
      api
        .getMediaInfo(plan.PlanContentPackageID)
        .then((mediaInfo: MediaInfo) => {
          api.downloadMedia(mediaInfo.DownloadToken);
        })
        .then(() => {
          // Save plan to realm (native) or local storage (browser)
          doa.savePlan(plan, password);
          setDownloads(downloads.filter(d => d.planId === plan.PlanID));
        })
        .catch(() =>
          downloads.map(d =>
            d.planId === plan.PlanID
              ? {...d, status: 'error' as DownloadStatus}
              : d,
          ),
        );
    }

    return {planId: plan.PlanID, status: 'pending' as DownloadStatus};
  };

  const downloadStatus = (planId: string) => {
    const currentDownload = downloads.find(d => d.planId === planId);
    if (currentDownload) {
      return currentDownload.status;
    } else if (doa.getDocument('Plan', planId)) {
      return 'downloaded' as DownloadStatus;
    } else {
      return 'ready' as DownloadStatus;
    }
  };

  const checkForUpdates = (): Promise<UpdatesResponse> => {
    const plans = doa.getCollection<Plan>('Plan');

    return api
      .getVersions(plans.map((plan: Plan) => plan.planID))
      .then((updated: PlanSearchResult[]) => {
        // Create hash of plans for easy comparisons
        const hash: {[key: string]: PlanSearchResult} = updated.reduce(
          (acc, p) => ({
            ...acc,
            [p.PlanID]: p,
          }),
          {},
        );
        return {
          updated: plans.filter((plan: Plan) => {
            const dotSplit = (x: string): Array<number> =>
              x.split('.').map(e => parseInt(e, 10));
            /**
             *
             * @param {string} a New Updated Version From HashMap
             * @param {string} b Current Local Plan Version
             * @returns {boolean} if there is an new update
             * to the version that is stored locally
             */
            const versionNumberCompare = (
              a: string,
              b: string = '0.0',
            ): boolean => {
              const x = dotSplit(a);
              const y = dotSplit(b);
              for (const i in x) {
                // get same position from b
                y[i] = y[i] || 0;
                // compare numarical value of position
                if (x[i] === y[i]) {
                  continue;
                  // if at any point they are not eq
                  // return true if updateVersion value is larger
                } else if (x[i] > y[i]) {
                  return true;
                } else {
                  return false;
                }
              }
              // perfectly equal to the last increment
              // take the one with more number elements in it
              return y.length > x.length;
            };

            return (
              versionNumberCompare(
                hash[plan.planID].Version,
                plan.version?.toString(),
              ) &&
              hash[plan.planID].IsActive &&
              !hash[plan.planID].IsExpired &&
              hash[plan.planID].Enabled &&
              !hash[plan.planID].RestrictWebAccess
            );
          }),
          removed: plans.filter(
            (plan: Plan) =>
              !hash[plan.planID].IsActive ||
              hash[plan.planID].IsExpired ||
              !hash[plan.planID].Enabled ||
              hash[plan.planID].RestrictWebAccess,
          ),
        };
      });
  };

  const updatePlan = (plan: Plan) => {
    let planInformation: PlanInfo;
    return api
      .getPlanInfo(plan.planID, plan.password)
      .then((info: PlanInfo) => {
        // assign plan
        planInformation = info;
        // Download plan content
        return api.getMediaInfo(info.PlanContentPackageID);
      })
      .then(() => doa.updatePlan(planInformation, plan.password || ''));
  };

  return {
    download: async (id: string, password: string = '') => {
      const planInfo: PlanInfo = await api.getPlanInfo(id, password || '');
      setDownloads([...downloads, download(planInfo, password)]);
      return null;
    },
    downloads,
    downloadStatus,
    deletePlan,
    deleting,
    checkForUpdates,
    updatePlan,
  };
};

export default usePlans;
