import {formToEmail, injectFormValues} from './forms';
import api, {PlanFormEmailData} from '../api';

export interface ContentData {
  contentBody: HTMLElement;
  id: string;
  parsedContent: Document;
  source: string;
  customEmailToInput: any;
  customEmailFromInput: any;
  customEmailStaticAddresses: any;
  sendNotification: Function;
  setPlanHasForm: Function;
  bumpReset?: Function;
}

// ------------------------------
// Helper Functions

/**
 * Helper function returns value of HTMLInputElement
 * @param selector DOM selector as string to query content for.
 * @param content DOM tree of container element to be queried.
 */
const getInputValue = (selector: string, content: HTMLElement) =>
  (content.querySelector(selector) as HTMLInputElement)?.value;

/**
 * Helper function returns string stripped of special characters in an array
 * @param subject Email subject as string.
 */
const stripStringForSubject = (subject: string): string => subject
  ?.replace(/[^-@,.\w\s]/gi, '');

/**
 * Helper function returns string stripped of non-email
 * special characters in an array.
 * @param email Email address string.
 */
const stripStringForEmail = (email: string): string[] => email
  ?.replace(/\s/g, '')
  .replace(/[^-@,.\w\s]/gi, '')
  .split(',')
  .filter(s => s);


// ------------------------------
// Localstorage

/**
 * Create id from uri, to save/access localstorage item.
 * @param uri props.source.uri
 */
export const simplifyContentId = (uri: string): string => {
  return uri
    ? uri.replace(/(file:\/\/\/plans\/)/gi, '').replace(/(.html)/gi, '')
    : '';
};

/**
 * Simplify stringified dom content for saving to localhost.
 * @param serialized Pre-serialized dom string.
 */
const simplifySerializedContent = (serialized: string): string => {
  return serialized
    .replace(
      /(<div xmlns="http:\/\/www.w3.org\/1999\/xhtml"><html><head><\/head><body>)/gi,
      '',
    )
    .replace(/(\n\n)/gi, '')
    .replace(/(<\/body><\/html><\/div>)/gi, '');
};


// ------------------------------
// Form Actions

/**
 * When "Save" button is pressed in a Plan form, take string
 * snapshot of current DOM state and write it to localstorage.
 *
 * @param contentData All data from component.
 * @param formData DOM form element.
 */
const onFormActionSave = (contentData: ContentData, formData: {[k: string]: any}) => {
  const {id, contentBody} = contentData;
  const stringForm = new window.XMLSerializer().serializeToString(contentBody);
  const serialized = injectFormValues(stringForm, formData);
  const simplifiedContent = simplifySerializedContent(serialized);
  window.localStorage.setItem(id, simplifiedContent);
  contentData.sendNotification('info', 'Form saved successfully');
};

/**
 * When "Reset" button is pressed in a Plan form, clear DOM state and delete from localstorage
 *
 * @param contentData All data from component.
 * @param formData DOM form element.
 */
 const onFormActionReset = (contentData: ContentData, formData: {[k: string]: any}) => {
  const {id, contentBody, bumpReset} = contentData;
  const stringForm = new window.XMLSerializer().serializeToString(contentBody);
  for (let key in formData) {
    if (!['emailAddresses', 'subject', '', 'button'].includes(key)) {
      formData[key] = '';
    }
  }

  const serialized = injectFormValues(stringForm, formData);
  const simplifiedContent = simplifySerializedContent(serialized);
  window.localStorage.setItem(id, simplifiedContent);

  bumpReset && bumpReset();
  contentData.sendNotification('info', 'Form reset successfully');
};

/**
 * When "Submit" button is pressed in a Plan form, construct
 * email data from form & additional inputs, and send data to
 * lambda to be sent with SES.
 *
 * @param contentData All data from component.
 * @param formData DOM form element.
 */
const onFormActionSubmit = (contentData: ContentData, formData: {[k: string]: any}) => {
  const {contentBody, customEmailFromInput, customEmailToInput} = contentData;
  const subject = stripStringForSubject(getInputValue('[name=subject]', contentBody));
  // format and combine default and additional TO email addresses
  const emailToDefault = stripStringForEmail(getInputValue('[name=emailAddresses]', contentBody));
  const emailToAdditional = stripStringForEmail(customEmailToInput.value);
  const emailTo = [...emailToDefault, ...emailToAdditional];
  // construct email BODY content
  const newEmailBody = createSubmitEmailBody(customEmailFromInput, contentBody);
  const body = formToEmail(newEmailBody, formData);
  // send form data to lambda
  const emailData: PlanFormEmailData = { body, emailTo, subject };
  api.sendPlanFormEmail(emailData)
    .then((res) => res && contentData.sendNotification('info', 'Email sent succesfully'))
    .catch(err => contentData.sendNotification('warn', err.message))
};


const onFormSubmit = (e: Event, form: HTMLFormElement, contentData: ContentData) => {
  e.preventDefault();
  const btnValue = e.submitter.value;
  const action = e.submitter.innerText;
  const formData = Object.fromEntries(new FormData(form).entries());
  // stringify current dom state and save to localstorage
  if (action === 'Save' || btnValue === 'Save') {
    onFormActionSave(contentData, formData);
  // gather form inputs, construct email body, send data to lambda
  } else if (action === 'Submit' || btnValue === 'Submit') {
    onFormActionSubmit(contentData, formData);
  } else if (action === 'Reset' || btnValue === 'Reset') {
    onFormActionReset(contentData, formData);
  }
};

/**
 * Include "Sent From" in body of SUBMIT email, if applicable.
 *
 * @param customEmailToInput Ref to container of optional "FROM" email inputs
 * @param contentBody Original body of the Plan
 */
const createSubmitEmailBody = (customEmailFromInput: any, contentBody: HTMLElement): string => {
  let newEmailContentBody = contentBody;
  // gather custom TO email addresses if needed
  if (customEmailFromInput.value) {
    const separator = '------';
    const emailToAddresses = stripStringForEmail(customEmailFromInput.value);
    // insert visual SENDER text for email body and
    // construct SENDER / faux-FROM email DOM elements
    emailToAddresses.unshift(...['<p>' + separator, '<strong>Sent from:</strong><br/>']);
    emailToAddresses.push(...[separator, '</p>']);
    const senderData = emailToAddresses.join('<br/>');
    // insert SENDER / faux-FROM email addresses into email body
    const newContentBody = document.importNode(contentBody, true);
    newContentBody.querySelector('body')?.insertAdjacentHTML('afterbegin', senderData);
    newEmailContentBody = newContentBody;
  }
  // remove injected stylesheet
  newEmailContentBody.querySelector('style')?.remove();
  const stringForm = new window.XMLSerializer().serializeToString(newEmailContentBody);
  return stringForm;
};


// ------------------------------
// Construct Template DOM

const attachAnchorFragmentHandlers = ({ parsedContent }: ContentData) => {
  const anchorTags = parsedContent.getElementsByTagName('a');
  for (let anchorTag of anchorTags) {
    anchorTag.onclick = (event: MouseEvent) => {
      const anchorTarget = anchorTag.getAttribute('href');
      if (anchorTarget.startsWith('#')) {
        event.preventDefault();
        document.getElementById(anchorTarget.replace('#', ''))?.scrollIntoView();
      }
    };
  }
};

/**
 * Injects additional font styles into plan form
 * to make form more readable.
 * Also injects nested html/body styles to correctly
 * display/align contents.
 *
 * @param ContentData.parsedContent #document of plan form.
 */
const injectViewStyles = ({parsedContent}: ContentData): Document => {
  const styleSheet = parsedContent.createElement('style');
  styleSheet.innerText = `
    * {
      font-family: sans-serif;
      line-height: 1.42;
    }
    div html {
      display: relative;
    }
    div body {
      display: relative;
      flex-direction: column;
      align-items: stretch;
      width: 100%;
      height: 100%;
      flex: 1;
      overflow: unset;
    }
    div body table {
      height: 100% !important;
      border-collapse: collapse;
      border-color: rgba(0,0,0,0.34);
    }
    div body table td {
      padding: 12px 10px;
      line-height: 1.2;
      border-color: rgba(0,0,0,0.34);
    }
  `;
  parsedContent.body.appendChild(styleSheet);
  return parsedContent;
};

export const injectFormActionFunctionality = (contentData: ContentData): Document => {
  const form = contentData.parsedContent.querySelector('form');
  if (form) {
    // no autocomplete in Plan form if present
    form.autocomplete = 'off';
    // populate existing TO emailAddresses in ui if they exist
    // setTimeout ensures element exists to query
    setTimeout(() => {
      // now we can tell the template for sure if form is available
      contentData.setPlanHasForm(true);
      // strip all non-email special characters from TO email
      const strippedEmailTo = getInputValue('[name=emailAddresses]', contentData.contentBody)?.replace(/[^-@.\w\s]/gi, '');
      if (strippedEmailTo) {
        // show email in ui if emailAddresses found
        contentData.customEmailStaticAddresses.innerHTML = strippedEmailTo + ' (default)';
        contentData.customEmailStaticAddresses.style.display = 'block';
      }
    });
    // add default save and submit functionality
    form.onsubmit = (e: Event) => onFormSubmit(e, form, contentData);
    // capture input change events to explicitly define current
    // dom state of boolean inputs to serializeToString later.
    form.oninput = (e: Event) => {
      const target = e.target as HTMLInputElement;
      if (target) {
        if (target.type === 'checkbox') {
          if (target.hasAttribute('checked')) target.removeAttribute('checked');
          else if (!target.hasAttribute('checked')) target.setAttribute('checked', 'true');
        }
        if (target.type === 'radio') {
          const radioGroup = form.querySelectorAll(`input[name='${target.name}']`);
          for (const radioInput of radioGroup) radioInput.removeAttribute('checked');
          target.setAttribute('checked', 'true');
        }
      }
    };
  }
  return contentData.parsedContent;
};

export const getContentHtml = (contentData: ContentData): HTMLHtmlElement | null => {
  let {parsedContent} = contentData;
  // inject code to expand parsing functionality
  parsedContent = injectFormActionFunctionality(contentData)
  // force sans-serif font for content
  parsedContent = injectViewStyles(contentData);
  // fixes anchor fragments to work with react navigation
  attachAnchorFragmentHandlers(contentData);
  // can't append #document object directly to contentBody,
  // so return html as "top level" instead
  return parsedContent.querySelector('html');
};
