import React from 'react';
import {View, Text, Platform} from 'react-native';
import {SafeArea} from '../styled-components/SafeArea';
import {Header, HeaderWrapper, Title} from '../styled-components/Header';
import ScrollView from './general/ScrollView';
import WebView from './general/WebView';
import styled from 'styled-components/native';
import Config from 'react-native-config';
import label from '../label';

const Content = styled(View)`
  min-height: 300px;
`;

const Footer = styled(View)`
  align-items: center;
  min-height: 200px;
  padding-bottom: 100px;
  justify-content: center;
`;

const FooterLine = styled(Text)`
  color: #666666;
  margin: 4px 0;
`;

const About = () => {
  return (
    <>
      <SafeArea>
        <HeaderWrapper />
      </SafeArea>
      <Header>
        <Title>About</Title>
      </Header>
      <ScrollView>
        <Content>
          <WebView
            source={
              Platform.OS === 'android'
                ? {
                    uri: 'file:///android_asset/about.html',
                  }
                : require('../assets/about.html')
            }
          />
        </Content>
        <Footer>
          <FooterLine>{label.copyright}</FooterLine>
          <FooterLine>
            Version{' '}
            {Platform.OS === 'ios'
              ? Config.IOS_VERSION
              : Config.ANDROID_VERSION}
          </FooterLine>
        </Footer>
      </ScrollView>
    </>
  );
};

export default About;
